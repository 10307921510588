<template>
    <main class="main" v-bar>
        <div>
            <div class="container projects_title">
                <div class="pre_title">{{ $t('projects.subtitle') }}</div>
                <h2>{{ $t('projects.title') }}</h2>
            </div>
            <ul class="filters container">
                <li @click="filtersChange('all')" class="filter" :class="{ 'active': filterType === 'all' }">{{ $t('projects.cat.all') }}</li>
                <li @click="filtersChange('business')" class="filter" :class="{ 'active': filterType === 'business' }">{{ $t('projects.cat.business') }}</li>
                <li @click="filtersChange('social')" class="filter" :class="{ 'active': filterType === 'social' }">{{ $t('projects.cat.social') }}</li>
                <li @click="filtersChange('projects')" class="filter" :class="{ 'active': filterType === 'projects' }">{{ $t('projects.cat.projects') }}</li>
                <li @click="filtersChange('activities')" class="filter" :class="{ 'active': filterType === 'activities' }">{{ $t('projects.cat.activities') }}</li>
            </ul>
            <div class="project_cards_wrap container">
                <router-link class="project_card" :to="$i18nRoute({ name: 'project', params: { id: item.id } })" v-for="(item, index) in filteredList" :key="index">
                    <div class="project_card_pretitle">{{item[currentLanguage].pretitle}}</div>
                    <div class="project_card_title">{{item[currentLanguage].title}}</div>
                    <div class="project_card_text">{{item[currentLanguage].text | truncate(100) }}</div>
                    <div class="cell" :style="{ background: item.color}">
                        <div class="cell_bg"></div>
                        <div class="cell_content">
                            <img :src="item.lightLogo" alt="logo">
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </main>
</template>

<script>
import { TimelineMax, TweenMax } from 'gsap';
import { mapGetters } from 'vuex';
import { Trans } from '@/shared/utils/TranslateMiddleware';
import truncate from '@/shared/filters/truncate'; // eslint-disable-line

export default {
    metaInfo() {
        return {
            title: `${this.$t('header.title')} - ${this.$t('menu.projects')}`,
        }
    },
    name: 'HomePageComponent',
    computed: {
        ...mapGetters('projects', {
            list: 'getList'
        }),
        currentLanguage () {
            return Trans.currentLanguage;
        }
    },
    data: () => ({
        filterType: 'all',
        filteredList: [],
        // list: [
        //     {
        //         type: 'business',
        //         pretitle: 'бізнес',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'business',
        //         pretitle: 'бізнес',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'business',
        //         pretitle: 'бізнес',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'social',
        //         pretitle: 'соцiальнi',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'business',
        //         pretitle: 'бізнес',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'business',
        //         pretitle: 'бізнес',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'business',
        //         pretitle: 'бізнес',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        //     {
        //         type: 'social',
        //         pretitle: 'соцiальнi',
        //         title: 'Таврійські Ігри',
        //         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        //         src: '/img/logo.png',
        //         color: '#6698FF'
        //     },
        // ]
    }),
    mounted() {
        this.filtersChange('all');
    },
    watch: {
        list() {
            this.filtersChange('all');
        }
    },
    methods: {
        filtersChange (type) {
            this.filterType = type;
            this.filteredList = type === 'all' ? this.list : this.list.filter(item => item.type === type);

            setTimeout(function(){
                var tl = new TimelineMax();
                TweenMax.set(('.project_card'), {opacity: 0});
                tl
                    .staggerTo(('.project_card'), 0.3, {opacity: 1}, 0.1)
            }, 0)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>